import React, { useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import InputMask from 'react-input-mask';


function Page1({
    onChangeTipoPagamento,
    onSubmit,
    onCheck,
    showSuspender,
    showReembolso,
    showMensagem,
    showSucesso,
    voucher,
    tipoPagamento,
    loader, setShowReembolso,
    setShowSuspender,
    setShowMensagem,
    form,
    onChageValue,
    setshowSucesso,
    tipoChavePix,
    onChangeTipoChavePix }) {
    return (
        <div className="page">
            <div className="container-divider">
                <div className="bg-imagem">
                    {!loader && <div className="box-opcao" style={showSucesso ? { width: '80%', height: '750px !important;' } : { display: 'flex' }}>
                        {!showMensagem && <>
                            {(showSuspender || showReembolso) && <h5 onClick={e => { setShowSuspender(false); setShowReembolso(false) }} style={{ textAlign: 'start', color: '#fff' }}>Voltar</h5>}
                            {(!showSuspender && !showReembolso) && <h2>Selecione a opção desejada:</h2>}
                            {(!showSuspender && !showReembolso) && <div className="container-opcao" onClick={e => setShowReembolso(true)}>
                                Clique aqui, se identificou um desconto indevido.
                            </div>}
                            {(!showSuspender && !showReembolso) && <div className="container-opcao" onClick={e => setShowSuspender(true)}>
                                Clique aqui, se você é associado e deseja suspender a sua associação.
                            </div>}
                            {showSuspender && <div className="container-alerta">
                                Para desfiliar-se da AASPA, acesse nossos canais de atendimento:<br />
                                <BsTelephone color='var(--cor-principal)' /> Telefones: 0800-456-0005 ou (31) 2117-5404  <br />
                                <FaWhatsapp color='var(--cor-principal)' />Whatsapp: <a href="https://contate.me/aaspa" target='_blank'>(31) 99533-9530</a>
                            </div>}
                            {showReembolso && <form className='row container-form' onSubmit={e => { e.preventDefault(); onCheck() }}>
                                <h3>Se identificou desconto indevido, Para agilizarmos o seu atendimento, digite :</h3>
                                <div className="col-md-6">
                                    <label>CPF*:</label>
                                    <InputMask
                                        mask="99999999999"
                                        value={form.cpf}
                                        onChange={onChageValue}
                                        placeholder='___________'
                                        className='form-control'
                                    >
                                        {(inputProps) => <input {...inputProps} required type="text" name='cpf' minLength={14} />}
                                    </InputMask>
                                </div>
                                <div className="col-md-6">
                                    <label>Nr. Beneficio*:</label>
                                    <InputMask
                                        mask="9999999999"
                                        value={form.beneficio}
                                        onChange={onChageValue}
                                        placeholder='__________'
                                        className='form-control'
                                    >
                                        {(inputProps) => <input {...inputProps} required type="text" name='beneficio' minLength={11} />}
                                    </InputMask>
                                </div>
                                <div className="align-center">
                                    <button>Enviar</button>
                                </div>
                            </form>}
                        </>}
                        {showMensagem && !voucher && <h5 onClick={e => { setshowSucesso(false); setShowMensagem(false) }} style={{ textAlign: 'start', color: '#fff' }}>Voltar</h5>}
                        {showMensagem && !voucher && <div className='container-alerta'>
                            {showSucesso && <>
                                Identificamos que por erro no sistema, a integração com o INSS nesta ultima folha gerou desconto indevido em alguns benefícios, pois a chave de desconto é apenas o número do beneficio. <br />
                                Lamentamos o ocorrido, mas já identificamos a falha , e como não havia tempo hábil para cancelamento do desconto, preparamos nossos sistemas para efetuar o reembolso logo após sua solicitação . <br />
                                <form style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '3rem' }} className='row container-form' onSubmit={e => { e.preventDefault(); onSubmit() }}>
                                    <h3 style={{ textAlign: 'center' }}>Informe abaixo os dados bancários para deposito:</h3>

                                    <div className="row" style={{ width: '100%' }}>
                                        <div className="col-md-6">
                                            <label>Nome completo*:</label>
                                            <input maxLength={250} value={form.nome} name='nome' onChange={onChageValue} required className='form-control' type="text" />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Telefone para contato*:</label>
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={form.telefone}
                                                onChange={onChageValue}
                                                placeholder='(__) _____-____'
                                                className='form-control'
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" name='telefone' />}
                                            </InputMask>
                                        </div>
                                    </div>

                                    <div className="row" style={{ width: '100%' }}>
                                        <div className="col-md-6">
                                            <label>Cpf*:</label>
                                            <InputMask
                                                style={{ background: '#c2c2c2' }}
                                                mask="999.999.999-99"
                                                value={form.cpf}
                                                onChange={onChageValue}
                                                placeholder='___.___.___-__'
                                                className='form-control'
                                                disabled
                                                readOnly
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" id='cpf2' name='cpf' minLength={14} />}
                                            </InputMask>
                                        </div>
                                        <div className="col-md-6">
                                            <label>Tipo de depósito:</label>
                                            <select onChange={onChangeTipoPagamento} className='form-control'>
                                                <option value="0">PIX</option>
                                                <option value="1">Dados bancários</option>
                                            </select>
                                        </div>
                                    </div>

                                    {tipoPagamento && <div className="row" style={{ width: '100%' }}>
                                        <div className="col-md-6">
                                            <label>Tipo Chave PIX*:</label>
                                            <select value={tipoChavePix} onChange={onChangeTipoChavePix} className='form-control'>
                                                <option value="CPF">CPF</option>
                                                <option value="CNPJ">CNPJ</option>
                                                <option value="telefone">telefone</option>
                                                <option value="e-mail">E-mail</option>
                                                <option value="chave aleatória">Chave aleatória</option>
                                            </select>
                                        </div>
                                        {tipoChavePix === "CPF" && <div className="col-md-6">
                                            <label>Chave PIX*:</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                value={form.pix}
                                                onChange={onChageValue}
                                                className='form-control'
                                                placeholder='___.___.___-__'
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" name='pix' />}
                                            </InputMask>
                                        </div>}
                                        {tipoChavePix === "CNPJ" && <div className="col-md-6">
                                            <label>Chave PIX*:</label>
                                            <InputMask
                                                mask="99.999.999/9999-99"
                                                value={form.pix}
                                                onChange={onChageValue}
                                                className='form-control'
                                                placeholder='__.___.___/____-__'
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" name='pix' />}
                                            </InputMask>
                                        </div>}
                                        {tipoChavePix === "telefone" && <div className="col-md-6">
                                            <label>Chave PIX*:</label>
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={form.pix}
                                                onChange={onChageValue}
                                                className='form-control'
                                                placeholder='(__) _____-____'
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" name='pix' />}
                                            </InputMask>
                                        </div>}
                                        {tipoChavePix === "e-mail" && <div className="col-md-6">
                                            <label>Chave PIX*:</label>
                                            <input placeholder='____@___.com' maxLength={250} value={form.pix} onChange={onChageValue} name='pix' required className='form-control' type="email" />
                                        </div>}
                                        {tipoChavePix === "chave aleatória" && <div className="col-md-6">
                                            <label>Chave PIX*:</label>
                                            <input placeholder='_______-____-____-____-____________' maxLength={250} value={form.pix} onChange={onChageValue} name='pix' required className='form-control' type="text" />
                                        </div>}
                                    </div>}

                                    {!tipoPagamento && <div className='row' style={{ width: '100%' }}>
                                        <div className="col-md-4">
                                            <label>Banco*:</label>
                                            <input maxLength={250} value={form.banco} onChange={onChageValue} name='banco' required className='form-control' type="text" />
                                        </div>

                                        <div className="col-md-3">
                                            <label>Agência*:</label>
                                            <InputMask
                                                mask="9999"
                                                value={form.agencia}
                                                onChange={onChageValue}
                                                placeholder='____'
                                                className='form-control'
                                            >
                                                {(inputProps) => <input {...inputProps} required type="text" name='agencia' />}
                                            </InputMask>
                                        </div>

                                        <div className="col-md-3">
                                            <label>Conta corrente*:</label><br />
                                            <input required maxLength={50} name='conta' value={form.conta} onChange={onChageValue} className='form-control' type="text" />
                                        </div>
                                        <div className="col-md-2">
                                            <label>Dígito*:</label><br />
                                            <input required maxLength={5} name='digito' value={form.digito} onChange={onChageValue} className='form-control' type="text" />
                                        </div>
                                    </div>}

                                    <div className="align-center">
                                        <button>Enviar</button>
                                    </div>
                                </form>

                            </>}
                            {!showSucesso && <div className="container-alerta">
                                Não identificamos o desconto pelo ultimo processamento do INSS, acesse nossos canais de atendimento para melhor orientarmos. <br />
                                <BsTelephone color='var(--cor-principal)' /> Telefones: 0800-456-0005 ou (31) 2117-5404  <br />
                                <FaWhatsapp color='var(--cor-principal)' />Whatsapp: <a href="https://contate.me/aaspa" target='_blank'>(31) 99533-9530</a>
                            </div>}
                        </div>}
                        {voucher && <div className="container-alerta">
                            Solicitação  gravada com sucesso.
                            Estaremos providenciando a devolução em até 48 horas ou na data de credito do seu beneficio.
                            Anote este protocolo : <b>{voucher}</b>
                            <br /><br />
                            <FaCheckCircle size={100} color='green' />
                            <br />
                            <a href="btnvoltar">Voltar</a>
                        </div>}
                    </div>}
                    {loader && <div className="box-opcao">
                        <div class="dots-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div >
    );
}
export default Page1;